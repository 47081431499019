import React, { useState, useEffect } from 'react';
import { db, realtimedb } from '../App';
import { doc, onSnapshot, getDocs, collection, getDoc } from "firebase/firestore";
import { ref, onValue } from "firebase/database";
import { AnimatePresence, motion } from 'framer-motion';

const styles = {
    container: {
        fontSize: "23px",
        position: 'absolute',
        top: 20,
        right: 20,
        fontWeight: 'bold',
        width: 450,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        border: "solid",
        borderWidth: 5,
        borderColor: "#9D4BAD",
        borderRadius: 15,
        padding: "12.5px 25px",
        color: "#FFFFFF",
    },
    commentator: {
        marginTop: "15px",
    }
}

const containerVariant = {
    hidden: {
        y: -200,
    },
    visible: {
        y: 0,
    },
    exit: {
        y: -200,
    }
}

const Roster = ({ tableName }) => {
    const [members, setMembers] = useState([]);
    const [membersData, setMembersData] = useState([]);
    const [speakingData, setSpeakingData] = useState([]); // [participantId, participantId, ...
    const [rosterMapping, setRosterMapping] = useState({});

    const [, setState] = React.useState();
    const forceUpdate = React.useCallback(() => setState({}), []);

    const getParticipant = (participantId) => {
        // Get the participant document from Firestore
        participantId = participantId.split("_")[0];

        if (rosterMapping.length === 0) {
            console.log("rosterMapping is null");
            return {};
        }

        if (rosterMapping[participantId] !== undefined) {
            return rosterMapping[participantId];
        }

        return { name: participantId, title: "Commentator" };
    }

    useEffect(() => {
        let unsubscribeRoster; // To hold the match listener unsubscribe function
        let unsubscribeSpeaking; // To hold the speaking listener unsubscribe function

        // Fetch data from Firestore and set up the listener
        const fetchData = async () => {
            if (!tableName) {
                return;
            }
            async function getCommentators() {
                console.log("current roster mapping: ", rosterMapping);
                if (Object.keys(rosterMapping).length === 0) {
                    const querySnapshot = await getDocs(collection(db, "commentators"));
                    querySnapshot.forEach((doc) => {
                        rosterMapping[doc.id] = doc.data();
                        forceUpdate();
                    });
                    console.log("rosterMapping updated");
                }
            }
            getCommentators();

            let rosterRef = doc(db, "rosters", tableName);
            let speakingRef = ref(realtimedb, `speaking/${tableName}`);

            unsubscribeSpeaking = onValue(speakingRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    setSpeakingData(data);
                }
            });

            unsubscribeRoster = onSnapshot(rosterRef, async (rosterDoc) => {
                if (rosterDoc.exists()) {
                    if (rosterDoc.data().members) {
                        const newMembers = rosterDoc.data().members;
                        setMembers(newMembers);
                        
                        // Fetch commentator data for each member
                        for (const member of newMembers) {
                            const commentatorId = member.split("_")[0];
                            const commentatorRef = doc(db, "commentators", commentatorId);
                            const commentatorDoc = await getDoc(commentatorRef);
                            
                            if (commentatorDoc.exists()) {
                                setRosterMapping(prev => ({
                                    ...prev,
                                    [commentatorId]: commentatorDoc.data()
                                }));
                            }
                        }
                    }
                    if (rosterDoc.data().membersData) {
                        setMembersData(rosterDoc.data().membersData);
                    }
                }
            });

        };

        fetchData();

        // Return a cleanup function to unsubscribe from Firestore listeners to prevent memory leaks
        return () => {
            if (unsubscribeRoster) {
                unsubscribeRoster();
            }
            if (unsubscribeSpeaking) {
                unsubscribeSpeaking();
            }
        };
    }, [tableName]);

    return (
        <AnimatePresence>
        { members.length > 0 && (
            <motion.div style={styles.container} variants={containerVariant} initial="hidden" animate="visible" exit="exit">
                <div style={styles.commentatorTitle}>COMMENTATORS</div>
                {members.map((participant) => (
                    <div key={participant} style={styles.commentator} className="flex items-center mt-2">
                        <img className="w-16 h-16 mr-4 border-4" style={{ borderColor: (membersData[participant] && membersData[participant].color)}} src={getParticipant(participant).profile_pic || "https://storage.googleapis.com/releases-evosports/profile_pics/evo_logo.jpg"} alt={getParticipant(participant).name} />
                        <div>
                            <p><span>{getParticipant(participant).name.toUpperCase()}</span> {membersData[participant] && membersData[participant].muted && <span className="text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block ml-2 mr-2 mb-2" viewBox="0 0 24 24" fill="none">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M15 9.4V5C15 3.34315 13.6569 2 12 2C10.8224 2 9.80325 2.67852 9.3122 3.66593M12 19V22M8 22H16M3 3L21 21M5.00043 10C5.00043 10 3.50062 19 12.0401 19C14.51 19 16.1333 18.2471 17.1933 17.1768M19.0317 13C19.2365 11.3477 19 10 19 10M12 15C10.3431 15 9 13.6569 9 12V9L14.1226 14.12C13.5796 14.6637 12.8291 15 12 15Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>} {membersData[participant] && !membersData[participant].muted && speakingData[participant] && speakingData[participant].speaking && <span className="text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block ml-2 mr-1" viewBox="0 0 24 24" style={{ fill: membersData[participant].color }}>
                                    <path xmlns="http://www.w3.org/2000/svg" d="M0,6 L0,12 L4,12 L9,17 L9,1 L4,6 L0,6 L0,6 Z M13.5,9 C13.5,7.2 12.5,5.7 11,5 L11,13 C12.5,12.3 13.5,10.8 13.5,9 L13.5,9 Z M11,0.2 L11,2.3 C13.9,3.2 16,5.8 16,9 C16,12.2 13.9,14.8 11,15.7 L11,17.8 C15,16.9 18,13.3 18,9 C18,4.7 15,1.1 11,0.2 L11,0.2 Z"/>
                                </svg>
                            </span>} </p>
                            <p className="text-gray-300">{getParticipant(participant).title}</p>
                            
                        </div>
                    </div>
                ))}
            </motion.div>
        )}
        </AnimatePresence>
    )
};

export default Roster;
